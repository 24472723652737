"use strict";

import Vue from "vue";
import axios from "axios";
import store from "@/store/index.js";
import config from "@/common/config.js";
import { showToast } from "@/common/modal.js";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let options = {
	baseURL: `${config.baseURL}/cln`,
	// timeout: 60 * 1000, // Timeout
	// withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(options);

_axios.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		let header = config.headers || {},
			method = config.method,
			token = config.token || store.state.token || "";
		const meta = config.meta || {} 

		if (method) {
			method = method.toUpperCase();
			if (method == "POST") {
				header = {
					"content-type": "application/json",
					...header,
				};
			} else {
				header = {
					"content-type": "application/x-www-form-urlencoded",
					...header,
				};
			}
		}
		if(!meta.noAuth) {
			if (token) {
				header.Authorization = `Bearer ${token}`;
			}
			const tenantId = store.state.tenantId;
			if(!tenantId) {
					store.dispatch("logout");
					console.log('请求时候没有租户ID，跳转登录')
					return Promise.reject()
			}else {
				header['TENANT-ID'] = tenantId;
			}
		}
		config.headers = header;
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

// Add a response interceptor
_axios.interceptors.response.use(
	function (response) {
		// Do something with response data
		const res = response.data;
		switch (res.code) {
			case 1:
				return res;
			case -2:
				store.dispatch("logout");
				break
		}
		showToast({
			msg: res.message || '请求失败，请稍后重试！'
		})
		return Promise.reject(res);
	},
	function (error) {
		const statusCode = error?.response?.request?.status;
    if(statusCode === 424) {
        // to re-login
        return store.dispatch("logout");
    }
		// Do something with response error
		return Promise.reject(error);
	}
);

const Plugin = {};

Plugin.install = function (Vue) {
	Vue.axios = _axios;
	window.axios = _axios;
	Object.defineProperties(Vue.prototype, {
		axios: {
			get() {
				return _axios;
			},
		},
		$axios: {
			get() {
				return _axios;
			},
		},
	});
};

Vue.use(Plugin);

export default _axios;

import axios from "@/plugins/axios.js";
import config from '@/common/config.js';

export function register(data) {
	return axios.request({
		url: "/register",
		method: "POST",
		data,
	});
}

export function getAuthUrl(params) {
	return axios.request({
		url: "/mch/wx/oauth2",
		method: "GET",
		baseURL: config.baseURL,
		params,
		meta: {
			noAuth: true,
		},
	});
}

export function login(data) {
	return axios.request({
		url: "/login",
		method: "POST",
		data,
	});
}

export function getOpenId(data) {
	return axios.request({
		method: "POST",
		url: "/auth",
		data,
	});
}

export function logout(data) {
	return axios.request({
		url: "/logout",
		method: "POST",
		data,
	});
}

export function getDetail(data) {
	return axios.request({
		url: "/invite/getDetail",
		method: "POST",
		data,
		meta: {
			noAuth: true,
		},
	});
}
export function  checkPassword(data) {
	return axios.request({
		url: "/checkPassword",
		method: "POST",
		data,
	});
}
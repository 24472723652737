import store from "@/store/index";

let timer = null
export function showToast({ msg = "", time = 2000 }) {
	return new Promise(function(resolve) {
		store.commit("SHOW_TOAST", msg);
		timer = setTimeout(function () {
			store.commit("HIDE_TOAST");
			resolve()
		}, time);
	})
}

export function closeToast() {
	store.commit("HIDE_TOAST");
	clearTimeout(timer)
}


export function showModal(opts) {
	return new Promise(function (resolve, reject) {
		const data = {
			...opts,
			resolve,
			reject,
		};
		store.commit("SHOW_MODAL", data);
	});
}

import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index"
import { encrypt, decrypt } from '@/common/crypto.js';

Vue.use(VueRouter);
const noLoginPath = [
	'/login',
	'/register',
];

const routes = [
	{
		path: "/",
		name: "order",
		component: () =>
			import(/* webpackChunkName: "order" */ "@/views/Order.vue"),
		meta: {title: "保洁列表"}
	},
	{
		path: "/login",
		name: "login",
		component: () =>
			import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
		meta: { title: "登录" },
	},
	{
		path: "/register",
		name: "register",
		component: () =>
			import(/* webpackChunkName: "register" */ "@/views/Register.vue"),
		meta: { title: "注册" },
	},
	{
		path: "/device",
		name: "deviceList",
		component: () =>
			import(/* webpackChunkName: "deviceList" */ "@/views/order/device/list/index.vue"),
		meta: { title: "设备列表" },
	},
	{
		path: "/upload",
		name: "uploadImg",
		component: () =>
			import(/* webpackChunkName: "uploadImg" */ "@/views/order/uploadImg.vue"),
		meta: { title: "上传图片" },
	},
];

const router = new VueRouter({
	routes,
});

router.beforeEach((to, _from, next) => {
	const queryTn = to.query.tn
	const tenantId = queryTn && decrypt(queryTn) 
	if(tenantId) {
		store.commit("SET_TENANT_ID", tenantId);
	}
	if(!store.state.isLogin && !noLoginPath.includes(to.path)) {
		next({
			name: 'login',
			query: {
				tn: encrypt(store.state.tenantId)
			}
		})
		return false
	}
	const title = to.meta?.title;
	if (title) {
		document.title = title;
	}
	next();
});

export default router;

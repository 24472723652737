const storage = {
	get(key) {
		let value = null;
		try {
			value = JSON.parse(localStorage.getItem(key));
		} catch (err) {
			value = null;
		}
		return value;
	},
	set(key, data) {
		localStorage.setItem(key, JSON.stringify(data));
	},
	remove(key) {
		localStorage.removeItem(key);
	},
};

export default storage;

import storage from "@/common/storage";
const AUTH_KEY = "auth";

const TENANT_KEY = "tenantId";

export function getAuth() {
	return storage.get(AUTH_KEY);
}

export function setAuth(authInfo) {
	storage.set(AUTH_KEY, authInfo);
}

export function clearAuth() {
	storage.remove(AUTH_KEY);
}

export function setTenantId(id) {
	return storage.set(TENANT_KEY, id);
}

export function getTenantId() {
	return storage.get(TENANT_KEY);
}

export function removeTenantId() {
	storage.remove(TENANT_KEY);
}

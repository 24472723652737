<template>
  <div id="app">
    <router-view />
    <div v-show="toast.isShow" class="toast-mask flex justify-center align-center">
      <div class="toast-box">{{toast.msg}}</div>
    </div>
    <div v-show="modal.isShow" class="modal-mask flex justify-center align-center">
      <div class="modal-box">
        <div class="modal-header">{{modal.title}}</div>
        <div class="modal-body">{{modal.msg}}</div>
        <div class="modal-footer flex">
          <div class="modal-btn cancel flex-1" @click="modalCancel">取消</div>
          <div class="modal-btn confirm flex-1" @click="modalComfirm">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(['toast', 'modal']),
  },
  created() {
  },
  methods: {
    modalCancel() {
      this.modal.reject()
      this.$store.commit('HIDE_MODAL')
    },
    modalComfirm() {
      this.modal.resolve()
      this.$store.commit('HIDE_MODAL')
    }
  }
}
</script>

<style>
html,
body {
  height: 100%;
}
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 26px;
}
body,
ul,
li,
button {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

button,
input,
select,
textarea {
  border: none;
  outline: none;
  background-color: transparent;
}
a:focus {
  outline: none;
}
#app {
  height: 100%;
}
* {
  box-sizing: border-box;
}
</style>
<style lang="postcss">
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-sb {
  justify-content: space-between;
}
.justify-sa {
  justify-content: space-around;
}
.justify-end {
  justify-content: flex-end;
}
.align-center {
  align-items: center;
}
.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column {
  flex-direction: column;
}

.page {
  box-sizing: border-box;
  height: 100vh;
  background: #fff;
}
.common-btn {
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 22px;
  min-width: 200px;
  height: 60px;
  font-size: 28px;
  color: #fff;
  line-height: 40px;
  border-radius: 30px;
  &.btn-default {
    background: linear-gradient(180deg, #f1cf9f 0%, #b2825a 100%);
    box-shadow: 0px 2px 8px 0px #b2825a;
  }
  &.btn-primary {
    background: linear-gradient(90deg, #f1cf9f 0%, #b2825a 100%);
  }
  &.btn-plain {
    border: 2px solid #333;
    color: #333;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
  }
  &.btn-danger {
    background: linear-gradient(to right, #db8a8a 0%, #d61818 97%);
  }
  &.btn-disabled {
    background: #ccc;
    box-shadow: none;
  }
}
.toast {
  &-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &-box {
    padding: 20px 30px;
    max-width: 80%;
    font-size: 26px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 20px;
  }
}
.modal {
  &-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  &-box {
    position:relative;
    width: 70%;
    font-size: 26px;
    text-align: center;
    line-height: 40px;
    color: #333;
    background: #fff;
    border-radius: 20px;
  }
  &-header {
    padding: 0 20px;
    line-height: 80px;
    font-size: 32px;
    border-bottom: 1px solid #ccc;
  }
  &-body {
    padding: 20px;
  }
  &-footer {
    border-top: 1px solid #ccc;
  }
  &-btn {
    line-height: 80px;
    &.cancel {
      color: rgb(228, 21, 21);
      border-right: 1px solid #ccc;
    }
    &.confirm {
      color: rgb(26, 170, 26);
    }
  }

  &-close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 50px;
    height: 50px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 3px;
      background-color: #ccc;
    }
    &::before {
      transform: rotateZ(45deg);
    }
    &::after {
      transform: rotateZ(-45deg);
    }
  }
}
</style>
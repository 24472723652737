import CryptoJS from "crypto-js";

const secretKey = "shareKey12345654";

/**
 * 简单加密
 * @param str
 * @param key
 * @returns {string}
 */
export function encrypt(str= '', pass = secretKey) {

	const key = CryptoJS.enc.Utf8.parse(pass); // 通过密钥获取128位的key
  return CryptoJS.AES.encrypt(str, key, {
    mode: CryptoJS.mode.ECB
  }).toString();
}

/**
 * 解密
 * @param encrypted
 * @param key
 * @returns {string}
 */
export function decrypt(encryptedStr, pass = secretKey) {
	const key = CryptoJS.enc.Utf8.parse(pass); // 通过密钥获取128位的key
  const decryptedData = CryptoJS.AES.decrypt(encryptedStr, key, {
    mode: CryptoJS.mode.ECB
  });
  return decryptedData.toString(CryptoJS.enc.Utf8);
}

import Vue from "vue";
import Vuex from "vuex";
import { getAuth, setAuth, clearAuth, setTenantId, getTenantId  } from "@/common/auth.js";
import { login } from "API/user.js";
import router from "@/router/index";
import { encrypt } from '@/common/crypto.js'

Vue.use(Vuex);

function initState(state) {
	Object.assign(state, {
		token: "",
		isLogin: false,
		userInfo: null,
	});
	return state;
}

const state = initState({
	toast: {
		isShow: false,
		msg: "",
	},
	modal: {
		isShow: false,
		title: "",
		msg: "",
		reject: null,
		resolve: null,
	},
	platformInfo: null,
	tenantId: ''
});
const getters = {
	storeId(state) {
		return state.storeInfo && state.storeInfo.id;
	},
	platformImg(state) {
		return state.platformInfo?.brand_logo
	}
};
const mutations = {
	LOGIN(state, res) {
		state.token = res.token;
		state.userInfo = res.data;
		state.isLogin = true;
		setAuth(res);
	},
	LOGOUT(state) {
		initState(state);
		clearAuth();
	},
	SHOW_TOAST(state, msg) {
		state.toast.isShow = true;
		state.toast.msg = msg;
	},
	HIDE_TOAST(state) {
		state.toast.isShow = false;
		state.toast.msg = "";
	},
	SHOW_MODAL(state, opts) {
		const modal = state.modal;
		modal.title = opts.title || "";
		modal.msg = opts.msg || "";
		modal.isShow = true;
		modal.reject = opts.reject || function () {};
		modal.resolve = opts.resolve || function () {};
	},
	HIDE_MODAL(state) {
		state.modal.isShow = false;
	},
  SET_TENANT_ID: (state, tenantId) => {
    state.tenantId = tenantId;
		setTenantId(tenantId)
  },
	SET_PLATFORM_INFO(state, platformInfo) {
		state.platformInfo = platformInfo
	}
};
const actions = {
	async login({ commit }, data) {
		const res = await login(data);
		if (res.code !== 1) return Promise.reject();
		commit("LOGIN", res.data);
	},
	async logout({ commit, state }) {
		commit("LOGOUT");
		if (router.currentRoute.name === "login") return;
		router.replace({
			name: "login",
			query: {
				tn: encrypt(state.tenantId)
			}
		});
	},
};

const store = new Vuex.Store({
	state,
	getters,
	mutations,
	actions,
});

(function init() {
	const authInfo = getAuth();
	const tenantId = getTenantId();
	if (authInfo) {
		store.commit("LOGIN", authInfo);
	}
	if(tenantId) {
		store.commit("SET_TENANT_ID", tenantId);
	}
})();

window.store = store;
export default store;
